<template>
  <v-app>
    <SystemBar/>
    <v-main class="black">
      <v-container fluid class="pt-0 py-20" >
        <v-row>
          <v-col class="" lg="9" md="9" sm="12" xs="12">
            <TxTable/>
          </v-col>
          <v-col lg="3" md="3" sm="12" xs="12" class="ml-0 pl-0">
            <TrendTable/>
            <TrendTable10/>
          </v-col>
        </v-row>
        <!-- <v-row>
          <Ticker/>
        </v-row> -->
        <TokenBottom/>
        <WalletDialog/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// import Ticker from './components/Ticker'
import TxTable from './components/TxTable'
import SystemBar from './components/SystemBar.vue'
import TrendTable from './components/TrendTable.vue'
import TrendTable10 from './components/TrendTable10.vue'
import TokenBottom from './components/TokenBottom.vue'
import WalletDialog from './components/WalletDialog.vue'
export default {
  name: 'App',
  components: {
    // Ticker,
    SystemBar,
    TxTable,
    TrendTable,
    TrendTable10,
    TokenBottom,
    WalletDialog,
  },
  data: () => ({
    //
  }),
};
</script>