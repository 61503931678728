var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('v-data-table',{staticClass:"grey--text text--lighten-1",attrs:{"dark":"","height":"1800","loading":_vm.swaps[0]==null,"loading-text":"waiting for data...","search":_vm.search,"headers":_vm.swapHeaders,"items":_vm.swaps,"page":_vm.page,"items-per-page":250,"hide-default-footer":true,"mobile-breakpoint":"0"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"pl-3 pt-0"},[_c('v-col',{staticClass:"text-h6 grey--text font-weight-bold",attrs:{"align":"left"}},[_vm._v("TX")]),_c('v-spacer'),_c('v-col',{staticClass:"pt-0",attrs:{"sm":"2","align":"right"}},[_c('v-switch',{attrs:{"label":"in ETH","persistent-hint":"","dense":""},model:{value:(_vm.showEth),callback:function ($$v) {_vm.showEth=$$v},expression:"showEth"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"sm":"2","align":"right"}},[_c('v-switch',{attrs:{"label":"hide Bot","persistent-hint":"","dense":""},model:{value:(_vm.hideBot),callback:function ($$v) {_vm.hideBot=$$v},expression:"hideBot"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Filter","single-line":"","hide-details":"","dense":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"pr-0",attrs:{"sm":"3"}},[_c('v-combobox',{attrs:{"label":"Watch","hint":"Type then press ENTER","multiple":"","small-chips":"","clearable":"","dense":""},model:{value:(_vm.watchedTokens),callback:function ($$v) {_vm.watchedTokens=$$v},expression:"watchedTokens"}})],1),_c('v-col',{staticClass:"mr-3",attrs:{"cols":"1","align":"right"}},[_c('v-menu',{attrs:{"offset-y":"","bottom":"","left":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"grey lighten-1"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-tune")])],1)]}}])},[_c('v-card',{staticClass:"grey darken-3 pa-2",attrs:{"dark":""}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-checkbox',{staticClass:"shrink",attrs:{"hide-details":""},model:{value:(_vm.ethImpactFilter.enabled),callback:function ($$v) {_vm.$set(_vm.ethImpactFilter, "enabled", $$v)},expression:"ethImpactFilter.enabled"}}),_c('v-text-field',{staticClass:"shrink text-body-2",attrs:{"disabled":!_vm.ethImpactFilter.enabled,"clearable":"","label":"ETH value x impact%"},model:{value:(_vm.ethImpactFilter.minimum),callback:function ($$v) {_vm.$set(_vm.ethImpactFilter, "minimum", $$v)},expression:"ethImpactFilter.minimum"}})],1),_c('v-row',[_c('v-checkbox',{staticClass:"shrink",attrs:{"hide-details":""},model:{value:(_vm.impactFilter.enabled),callback:function ($$v) {_vm.$set(_vm.impactFilter, "enabled", $$v)},expression:"impactFilter.enabled"}}),_c('v-text-field',{staticClass:"shrink text-body-2",attrs:{"disabled":!_vm.impactFilter.enabled,"clearable":"","label":"min impact%"},model:{value:(_vm.impactFilter.minimum),callback:function ($$v) {_vm.$set(_vm.impactFilter, "minimum", $$v)},expression:"impactFilter.minimum"}})],1),_c('v-row',[_c('v-checkbox',{staticClass:"shrink",attrs:{"hide-details":""},model:{value:(_vm.ethFilter.enabled),callback:function ($$v) {_vm.$set(_vm.ethFilter, "enabled", $$v)},expression:"ethFilter.enabled"}}),_c('v-text-field',{staticClass:"shrink text-body-2",attrs:{"disabled":!_vm.ethFilter.enabled,"clearable":"","label":"min ETH"},model:{value:(_vm.ethFilter.minimum),callback:function ($$v) {_vm.$set(_vm.ethFilter, "minimum", $$v)},expression:"ethFilter.minimum"}})],1)],1)],1)],1)],1)],1)],1)]},proxy:true},{key:"item.block",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.block.toString().slice(-3)+'~'+ _vm.formatTime(item.timeStamp)))])]}},{key:"item.hash",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("https://etherscan.io/tx/" + (item.hash)),"target":"_blank"}},[_vm._v(" "+_vm._s(item.hash.slice(2,6))+" ")])]}},{key:"item.dex",fn:function(ref){
var item = ref.item;
return [(['UniV2','UniV3','Sushi','Shiba'].includes(item.dex))?_c('a',{attrs:{"href":("https://dexscreener.com/ethereum/" + (item.pool.toLowerCase())),"target":"_blank"}},[_vm._v(" "+_vm._s(item.dex)+" ")]):_c('a',[_vm._v(" "+_vm._s(item.dex))])]}},{key:"item.sc",fn:function(ref){
var item = ref.item;
return [(_vm.formatSc(item.sc).includes('0x') || _vm.formatSc(item.sc).includes('..'))?_c('span',{staticClass:"hoverable symbol",on:{"click":function($event){$event.stopPropagation();return _vm.callWalletDialog(item.sc,item,'mdi-file-document')}}},[_vm._v(" "+_vm._s('0x'+_vm.formatSc(item.sc).slice(2))+" ")]):_c('span',{staticClass:"hoverable symbol",on:{"click":function($event){$event.stopPropagation();return _vm.callWalletDialog(item.sc,item,'mdi-file-document')}}},[_vm._v(" "+_vm._s(_vm.formatSc(item.sc))+" ")])]}},{key:"item.exchanged",fn:function(ref){
var item = ref.item;
return [_c('v-card',{staticClass:"pr-2",attrs:{"rounded":"","color":"blue-grey darken-4","min-width":"24"}},[_c('div',[_c('span',{class:_vm.swapAmountColor(item),on:{"dblclick":function($event){return _vm.dblClickImg('')},"click":function($event){return _vm.clickImg(item.addressFrom)}}},[_vm._v(" "+_vm._s(_vm.formatAmount(item.amountFrom,item.ethval,item.addressFrom))+" ")]),_c('span',{staticClass:"hoverable symbol",on:{"click":function($event){$event.stopPropagation();return _vm.eventEmitter('TokenBottom',{symbol: item.symbolFrom, tokenAddr: item.addressFrom, pair: item.pool, dex: item.dex})}}},[_vm._v(" "+_vm._s(item.symbolFrom.slice(0,8))+" "),_c('img',{staticStyle:{"width":"14px","height":"14px"},attrs:{"src":item.iconFrom}})])]),_c('div',[_c('span',{class:_vm.swapAmountColor(item),on:{"dblclick":function($event){return _vm.dblClickImg()},"click":function($event){return _vm.clickImg(item.addressTo)}}},[_vm._v(" "+_vm._s(_vm.formatAmount(item.amountTo,item.ethval,item.addressTo))+" ")]),_c('span',{staticClass:"hoverable symbol",on:{"click":function($event){$event.stopPropagation();return _vm.eventEmitter('TokenBottom',{symbol: item.symbolTo, tokenAddr: item.addressTo, pair: item.pool, dex: item.dex})}}},[_vm._v(" "+_vm._s(item.symbolTo.slice(0,8))+" "),_c('img',{staticStyle:{"width":"14px","height":"14px"},attrs:{"src":item.iconTo}})])])])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [(item.price.includes('._..'))?_c('span',{class:item.color},[_vm._v(" 0."),_c('sub',[_vm._v(_vm._s(item.price.split('-')[1]))]),_vm._v(_vm._s(item.price.split('-')[0].slice(4))+" ")]):_c('span',{class:item.color},[_vm._v(" "+_vm._s(item.price)+" ")])]}},{key:"item.impact",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.ethImpactColor(item.ethImpact)},[_vm._v(_vm._s(_vm.formatImpact(item.impact)))])]}},{key:"item.sender",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"hoverable symbol",on:{"click":function($event){$event.stopPropagation();return _vm.callWalletDialog(item.sender,item,'mdi-wallet')}}},[_vm._v(_vm._s(item.sender.slice(2,7)))])]}},{key:"footer",fn:function(){return [_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }