<template>
  <div class="">
    <v-data-table
      dark
      height="1800"
      :loading="swaps[0]==null"
      loading-text="waiting for data..."
      :search="search"
      :headers="swapHeaders"
      :items="swaps"
      :page.sync="page"
      :items-per-page="250"
      @page-count="pageCount = $event"
      :hide-default-footer="true"
      class="grey--text text--lighten-1"
      mobile-breakpoint="0"
    >
      <template v-slot:top>
        
        <v-row class="pl-3 pt-0">
          <v-col align="left" class="text-h6 grey--text font-weight-bold">TX</v-col>
          <v-spacer></v-spacer>
          <v-col sm="2" align="right" class="pt-0">
            <v-switch
              v-model="showEth"
              label="in ETH"
              persistent-hint
              dense
            ></v-switch>
          </v-col>
          <v-col sm="2" align="right" class="pt-0">
            <v-switch
              v-model="hideBot"
              label="hide Bot"
              persistent-hint
              dense
              
            ></v-switch>
          </v-col>
          <v-col sm="2" class="">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Filter"
              single-line
              hide-details
              dense
              clearable
            ></v-text-field>
          </v-col>
          <v-col sm="3" class="pr-0">
            <v-combobox
              v-model="watchedTokens"
              label="Watch"
              hint="Type then press ENTER"
              multiple
              small-chips
              clearable
              dense
            ></v-combobox>
          </v-col>
          <v-col cols="1" align="right" class="mr-3">
            <v-menu
              offset-y
              bottom
              left
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  color="grey lighten-1"
                >
                  <v-icon>mdi-tune</v-icon>
                </v-btn>
              </template>
              <v-card dark class="grey darken-3 pa-2">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-checkbox
                        v-model="ethImpactFilter.enabled"
                        hide-details
                        class="shrink"
                      >
                      </v-checkbox>
                      <v-text-field
                        :disabled="!ethImpactFilter.enabled"
                        clearable
                        v-model="ethImpactFilter.minimum"
                        label="ETH value x impact%"
                        class="shrink text-body-2"
                      ></v-text-field>
                    </v-row>
                    <v-row>
                      <v-checkbox
                        v-model="impactFilter.enabled"
                        hide-details
                        class="shrink"
                      >
                      </v-checkbox>
                      <v-text-field
                        :disabled="!impactFilter.enabled"
                        clearable
                        v-model="impactFilter.minimum"
                        label="min impact%"
                        class="shrink text-body-2"
                      ></v-text-field>
                    </v-row>
                    <v-row>
                      <v-checkbox
                        v-model="ethFilter.enabled"
                        hide-details
                        class="shrink"
                      >
                      </v-checkbox>
                      <v-text-field
                        :disabled="!ethFilter.enabled"
                        clearable
                        v-model="ethFilter.minimum"
                        label="min ETH"
                        class="shrink text-body-2"
                      ></v-text-field>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
              
            </v-menu>
          </v-col>
        </v-row>
        
      </template>
      <template v-slot:item.block="{ item }">
          <!-- <span class="text-caption">{{ formatTime(item.timeStamp) }}</span> -->
          <span class="text-caption">{{ item.block.toString().slice(-3)+'~'+ formatTime(item.timeStamp)}}</span>
      </template>
      <template v-slot:item.hash="{ item }">
        <a :href="`https://etherscan.io/tx/${item.hash}`" target="_blank">
        {{ item.hash.slice(2,6) }}
        </a>
      </template>
      <template v-slot:item.dex="{ item }">
        <a :href="`https://dexscreener.com/ethereum/${item.pool.toLowerCase()}`" target="_blank" v-if="['UniV2','UniV3','Sushi','Shiba'].includes(item.dex)">
        {{ item.dex }}
        </a>
        <a v-else> {{ item.dex }}</a>
      </template>
      
      <template v-slot:item.sc="{ item }">
        <!-- <span v-if="formatSc(item.sc).includes('0x') || formatSc(item.sc).includes('..')">
          <v-icon small color="grey">mdi-skull</v-icon>
          <a :href="`https://etherscan.io/address/${item.sc}`" target="_blank">
          {{ formatSc(item.sc).slice(2) }}
          </a>
        </span> -->
        <span 
          v-if="formatSc(item.sc).includes('0x') || formatSc(item.sc).includes('..')" 
          class="hoverable symbol" 
          @click.stop="callWalletDialog(item.sc,item,'mdi-file-document')">
            <!-- <v-icon small color="grey">mdi-skull</v-icon> -->
            {{ '0x'+formatSc(item.sc).slice(2)}}
        </span>
        <!-- <span v-else>
          <a :href="`https://etherscan.io/address/${item.sc}`" target="_blank">
          {{ formatSc(item.sc) }}
          </a>
        </span> -->
        <span v-else class="hoverable symbol" @click.stop="callWalletDialog(item.sc,item,'mdi-file-document')">
          {{formatSc(item.sc)}}
        </span>
      </template>
      <!-- <template v-slot:item.symbolFrom="{ item }">
        <span
          :class="swapAmountColor(item.sc)"
          @dblclick="dblClickImg('')"
          @click="clickImg(item.symbolFrom.slice(0,8))"
        >
            {{ formatAmount(item.amountFrom,item.ethval,item.addressFrom) }}
        </span>
        <span class="hoverable symbol" @click.stop="eventEmitter('TokenBottom',{symbol: item.symbolFrom, tokenAddr: item.addressFrom, pair: item.pool, dex: item.dex})">
          {{ item.symbolFrom.slice(0,8) }}
        <img :src="item.iconFrom" style="width: 14px; height: 14px">
        </span>
        
      </template> -->
      <template v-slot:item.exchanged="{ item }">
        <v-card rounded class="pr-2" color="blue-grey darken-4" min-width="24">
        <div>
          <span
            :class="swapAmountColor(item)"
            @dblclick="dblClickImg('')"
            @click="clickImg(item.addressFrom)"
          >
              {{ formatAmount(item.amountFrom,item.ethval,item.addressFrom) }}
          </span>
          
          <span class="hoverable symbol" @click.stop="eventEmitter('TokenBottom',{symbol: item.symbolFrom, tokenAddr: item.addressFrom, pair: item.pool, dex: item.dex})">
            {{ item.symbolFrom.slice(0,8) }}
            <img :src="item.iconFrom" style="width: 14px; height: 14px">
          </span>
          
        </div>
        <div>
          <span
            :class="swapAmountColor(item)"
            @dblclick="dblClickImg()"
            @click="clickImg(item.addressTo)"
          >
            {{ formatAmount(item.amountTo,item.ethval,item.addressTo) }}
          </span>
          <span class="hoverable symbol" @click.stop="eventEmitter('TokenBottom',{symbol: item.symbolTo, tokenAddr: item.addressTo, pair: item.pool, dex: item.dex})">
            
            {{ item.symbolTo.slice(0,8) }}
            <img :src="item.iconTo" style="width: 14px; height: 14px" >
          </span>
          
        </div>
        </v-card>
      </template>

      <template v-slot:item.price="{ item }">
        <span :class="item.color" v-if="item.price.includes('._..')">
            0.<sub>{{ item.price.split('-')[1] }}</sub>{{item.price.split('-')[0].slice(4)}}
        </span>
         <span :class="item.color" v-else>
          {{item.price}}
          </span>
      </template>

      <template v-slot:item.impact="{ item }">
        <!-- <v-chip :color="getColor(item.impact)" outlined small v-if="ethImpactFilter.enabled">
        {{ formatImpact(item.impact) }} | {{ item.ethImpact.toFixed(2) }}
        </v-chip> -->
        <!-- <v-chip :color="getColor(item.impact)" outlined small v-if="knownSc[item.sc]">
        {{ formatImpact(item.impact) }}
        </v-chip> -->
        <span :class="ethImpactColor(item.ethImpact)">{{ formatImpact(item.impact) }}</span>
        <!-- <span :class="getColorText(item.impact)">{{ formatImpact(item.impact) }}</span> -->
      </template>

      <template v-slot:item.sender="{ item }">
        <!-- <a :href="`https://etherscan.io/address/${item.sender}`" target="_blank">
        {{ item.sender.slice(2,7) }}
        </a> -->
        <span class="hoverable symbol" @click.stop="callWalletDialog(item.sender,item,'mdi-wallet')">{{item.sender.slice(2,7)}}</span>
      </template>

      <template v-slot:footer>
        <v-pagination
          v-model="page"
          :length="pageCount"
        ></v-pagination>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import moment from 'moment'
  import BigNumber from 'bignumber.js'
  import axios from 'axios'
  import { EventHub } from './eventhub'
  import { usds, weth } from './ohlcloader'
  //const weth="0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
  const ethIcon="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png"

  export default {
    name: 'txtable',
    components: {
    },
    data() {
      return {
        wcard: {},
        page: 1,
        pageCount: 0,
        search: '',
        hideBot: false,
        showEth: false,
        watchedTokens: [],
        swaps: [],
        blockHeader: {blockNumber: '', blockTimeStamp: ''},
        knownSc: {},
        usdTokens: ['USDT','USDC','TUSD','UST','TrueUSD','DAI'],
        ethFilter: {
          //enabled: true,
          //minimum: 0.9
        },
        impactFilter: {
          //enabled: false,
          //minimum: 0.1
        },
        ethImpactFilter: {
          //enabled: true,
          //minimum: 0.005
        },
        buyTxtColor: 'green--text text--lighten-0',
        sellTxtColor: 'red--text text--lighten-1',
        buyCardColor: 'grey darken-4',
        sellCardColor: 'brown darken-4'
      }
    },
    sockets: {
      block(data) {
        this.blockHeader = data
      },
      swapemit(data) {
        try {
        // data.from.icon = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${data.from.address}/logo.png`
        // data.to.icon = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${data.to.address}/logo.png`
      
        
        this.pushDataOrNot(data)
        } catch {
          console.log('skipping error data',data.hash)
        }
        
      },
      connect() {
        console.log('Socket connected')
      }
    },
    computed: {
      formattedBlockTime(){
        if(this.blockHeader.blockTimeStamp == '') return '---'
        else return moment(this.blockHeader.blockTimeStamp*1000).format('HH:mm:ss')
      },
      swapHeaders() {
        return [
          { text: 'Block#', value: 'block', filterable: false, sortable: false},
          //{ text: 'Sold', value: 'symbolFrom', sortable: false, align: 'end'},
          { text: 'Token', value: 'exchanged', sortable: false, align: 'end'},

          { text: 'Price', value: 'price', sortable: false, filterable:false, align: 'end'},
          { text: '%', value: 'impact', filterable: false, sortable: false, align: 'center'},
          { text: 'Pool', value: 'dex', sortable: false, filterable:false},
          { text: 'Sc', value: 'sc', sortable: false,
            filter: value => {
              if(!this.hideBot) return true
              else{
                if(this.knownSc[value] && !this.knownSc[value].includes('mev'))return true
                else return false
              }
            },
          },
          { text: 'Sender', value: 'sender', sortable: false, filterable:true},
          { text: 'Hash', value: 'hash', align: 'start', filterable: false, sortable: false},
        ]
      }
    },
    methods: {
      callWalletDialog(wallet,item,title) {
        EventHub.$emit("WalletDialog", {
          title: title,
          address: wallet,
          token1: {
            address: item.addressFrom,
            decimals: item.decimalsFrom,
            symbol: item.symbolFrom,
            icon: item.iconFrom
          },
          token2: {
            address: item.addressTo,
            decimals: item.decimalsTo,
            symbol: item.symbolTo,
            icon: item.iconTo
          },
          
        })
      },
      formatTime(time){
        return moment(time).format('HH:mm')
      },
      ethImpactColor (impact) {
        const nimpact = Number(impact)
        if (nimpact >= 4) return 'purple--text text--darken-0'
        else if (nimpact >= 2) return 'red--text text--darken-0'
        else if (nimpact >= 0.5) return 'orange--text text--darken-0'
        else return ''
      },
      getColor (impact) {
        const nimpact = Number(impact)
        if (nimpact >= 0.10) return 'purple darken-0'
        else if (nimpact >= 0.05) return 'red darken-0'
        else if (nimpact >= 0.02) return 'orange darken-0'
        else return 'grey darken-0'
      },
      getColorText (impact) {
        const nimpact = Number(impact)
        if (nimpact >= 0.10) return 'purple--text text--darken-0'
        else if (nimpact >= 0.05) return 'red--text text--darken-0'
        else if (nimpact >= 0.02) return 'orange--text text--darken-0'
        else return 'grey--text text--darken-0'
      },
      swapAmountColor (data) {
        let rstyle = ''
        if(this.knownSc[data.sc] && !this.knownSc[data.sc].includes('mev'))rstyle =  'grey--text text--ligthen-1'
        else rstyle = 'grey--text text--darken-1'
        if(data.color === this.sellTxtColor)rstyle = rstyle + ` ${this.sellCardColor}`
        if(data.color === this.buyTxtColor)rstyle = rstyle + ` ${this.buyCardColor}`
        return rstyle
      },
      swapSymbolColor (data) {
        let rstyle = 'hoverable symbol'
        if(data.color === this.sellTxtColor)rstyle = rstyle + ` ${this.sellCardColor}`
        if(data.color === this.buyTxtColor)rstyle = rstyle + ` ${this.buyCardColor}`
        return rstyle
      },

      retrieveKnownSc() {
        axios.get(`${process.env.VUE_APP_MAIN_URL}/swap/knownsc`)
          .then(res => {
            this.knownSc = res.data.data
          })
          .catch(err=>console.log('retrieve KnownSc err',err))
      },

      async initSwapData() {
       
        await axios.get(`${process.env.VUE_APP_MAIN_URL}/swap/retrieve?limit=500`)
          .then(res => {
            console.log('retrieve completed!',res.data.length)
            console.log(`"${process.env.VUE_APP_MAIN_URL}/swap/retrieve?limit=500"`)
            const swapsdb = res.data.data
            //swapsdb.forEach(swap=>this.pushDataOrNot(swap,"push"))
            for(let swap of swapsdb){
              this.pushDataOrNot(swap,"push")
            }
            //this.swaps = this.swaps.concat(swapsdb.filter(this.dataFiltering))
          })
          .catch(err=>console.log('err',err))
      },
      dataFiltering(data){
        let pushThis = true
        data.exchanged = `${data.addressFrom}, ${data.symbolFrom}, ${data.addressTo}, ${data.symbolTo}`

        if(!data.ethImpact){
          if(data.ethval)data.ethImpact = Number(data.impact)*Number(data.ethval)
          else data.ethImpact=0
        }
        if(this.ethFilter.enabled){
          if(Number(data.ethval)<Number(this.ethFilter.minimum))pushThis=false
        }
        if(this.impactFilter.enabled){
          if(Number(data.impact)*100<Number(this.impactFilter.minimum))pushThis=false
        }
        if(this.ethImpactFilter.enabled){
          if(data.ethImpact<Number(this.ethImpactFilter.minimum))pushThis=false
        }
        if(this.watchedTokens.length>0){
          if(this.watchedTokens.includes(data.symbolFrom) || this.watchedTokens.includes(data.symbolTo)){
            pushThis=true
          }
        }
        return pushThis
      },
      
      pushDataOrNot(data,method){
        const pushThis = this.dataFiltering(data)
        if(pushThis){
          if(!data.timeStamp)data.timeStamp = new Date()
          if(data.addressTo.toLowerCase()==weth){
            data.iconTo=ethIcon
            data.symbolTo="ETH"
            data.price = this.formatFloat(Number(data.amountTo)/Number(data.amountFrom),8)
            data.color = this.sellTxtColor
            data.cardColor = this.sellCardColor
          }
          if(data.addressFrom.toLowerCase()==weth){
            data.iconFrom=ethIcon
            data.symbolFrom="ETH"
            data.price = this.formatFloat(Number(data.amountFrom)/Number(data.amountTo),8)
            data.color = this.buyTxtColor
            data.cardColor = this.buyCardColor
          }
          if(![data.addressFrom.toLowerCase(), data.addressTo.toLowerCase()].includes(weth)){
            if(usds.includes(data.addressFrom.toLowerCase())){
              data.price=this.formatFloat(Number(data.ethval)/Number(data.amountTo),8)
              data.color = this.buyTxtColor
              data.cardColor = this.buyCardColor
            }
            if(usds.includes(data.addressTo.toLowerCase())){
              data.price=this.formatFloat(Number(data.ethval)/Number(data.amountFrom),8)
              data.color = this.sellTxtColor
              data.cardColor = this.sellCardColor
            } else {
              data.price=this.formatFloat(Number(data.ethval)/Number(data.amountTo),8)
              data.color = this.buyTxtColor
              data.cardColor = this.buyCardColor
            }
          }
          
          if(method=="push")this.swaps.push(data)
          else this.swaps.unshift(data)
        }
      },
      formatAmount(amount, ethval, tokenAddress) {
        let amt = amount
        let suffix = ''
        if(this.showEth && usds.includes(tokenAddress.toLowerCase())){
          amt = ethval
          suffix = 'eth'
        }
        let res = this.formatFloat(amt)
        return res+suffix
      },
      formatSc(sctext) {
        const ifknown = this.knownSc[sctext]
        if(ifknown)return ifknown
        else {
          if(sctext.slice(2,6)==="0000")return '....'+sctext.slice(-4,sctext.length)
          else return sctext.slice(0,6)
        }
      },
      formatImpact(impact) {
        if(Math.abs(Number(impact))>100000000000000)return "\u221e"
        else return this.formatFloat(Number(impact)*100,2)
      },
      
      clickImg(key) {
        if(this.search != key)this.search = key
        else if(this.search == key){
          this.eventEmitter('TrendTable',key)
        }
      },
      dblClickImg() {
        this.search = ""
        this.eventEmitter('TrendTable','')
      },
      eventEmitter(eventName,payLoad) {
        EventHub.$emit(eventName,payLoad)
      },
      // async getCoinIcon(data) {
      //   const fromIcon = await cgk.coins.fetchCoinContractInfo(data.from.address)
      //                           .then( res => { return res.data.image.thumb })
      //                           .catch( () => {return ''})
      //   const toIcon = await cgk.coins.fetchCoinContractInfo(data.to.address)
      //                           .then( res => { return res.data.image.thumb })
      //                           .catch( () => { return '' })
      //   data.from.icon = fromIcon
      //   data.to.icon = toIcon
      //   return data
      // }
      formatFloat(number,dec=2) {
        try { number = Number(number) }
        catch { number = 0 }
        const absnum = Math.abs(number)
        if(number===0)return '-'
        else if(absnum<1){
          if(number.toString().includes('e')){
            const tmp = number.toFixed(20).replace(/\.?0+$/,"")
            const last00 = tmp.lastIndexOf('00')
            return '0._..'+tmp.slice(last00,last00+6)+'-'+last00
          }
          else return number.toFixed(dec)
        } else {
          let res = number
          if(absnum>=1000000000000)res=BigNumber(number).shiftedBy(-12).toNumber().toFixed(2)+'t'
          else if(absnum>=1000000000)res=BigNumber(number).shiftedBy(-9).toNumber().toFixed(2)+'b'
          else if(absnum>=1000000)res=BigNumber(number).shiftedBy(-6).toNumber().toFixed(2)+'m'
          else if(absnum>=10000)res=BigNumber(number).shiftedBy(-3).toNumber().toFixed(2)+'k'
          else if(absnum>=1)res=Number(number).toFixed(2)
          else res = Number(number).toFixed(2)
          return res
        }
      },
    },
    created() {
      this.retrieveKnownSc()
    },
    mounted() {
      if(localStorage.getItem('ethFilter'))
        this.ethFilter = JSON.parse(localStorage.getItem('ethFilter'))
      else {
        this.ethFilter = { enabled: true, minimum: 0.9 }
        localStorage.setItem('ethFilter', JSON.stringify(this.ethFilter))
      }
      if(localStorage.getItem('impactFilter'))
        this.impactFilter = JSON.parse(localStorage.getItem('impactFilter'))
      else {
        this.impactFilter = { enabled: false, minimum: 0.1 }
        localStorage.setItem('impactFilter', JSON.stringify(this.impactFilter))
      }
      if(localStorage.getItem('ethImpactFilter'))
        this.ethImpactFilter = JSON.parse(localStorage.getItem('ethImpactFilter'))
      else {
        this.ethImpactFilter = { enabled: true, minimum: 0.005 }
        localStorage.setItem('ethImpactFilter', JSON.stringify(this.ethImpactFilter))
      }
      
      this.initSwapData()
      EventHub.$on('TxTable', data => {
        if(this.search!=data)this.search = data
        else this.search=""
      })
    },
    
    watch: {
      swaps: function() {
        const maxdata = 1000
        if(this.swaps.length>maxdata+10){
          this.swaps = this.swaps.slice(0,maxdata)
        }
      },
      ethFilter: { 
        handler() {
          localStorage.setItem('ethFilter', JSON.stringify(this.ethFilter))
        },
        deep: true
      },
      impactFilter: {
        handler() {
          localStorage.setItem('impactFilter', JSON.stringify(this.impactFilter))
        },
        deep: true
      },
      ethImpactFilter: {
        handler() {
          localStorage.setItem('ethImpactFilter', JSON.stringify(this.ethImpactFilter))
        },
        deep: true
      }
    },
  }
</script>

<style scoped>
img {
  filter: brightness(70%);
}
a, a:link, a:visited {
  text-decoration: none;
  color: #60acdf;
}
a:hover {
  text-decoration: underline;
  color: #60acdf;
}

span.hoverable {
  cursor: pointer;
}

span.buy {
  color: #36a197;
  /* #adffd7; */
  /* #26a69a tdview */
}

span.sell {
  color: #db584f;
  /* #ffafaf; */
  /* #f44336 tdview*/
}

span.symbol {
  color: #60acdf;
  /* #ffafaf; */
  /* #f44336 tdview*/
}
</style>

// 64b3e7
