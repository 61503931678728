<template>
  <div>
    <v-system-bar dark app class="" height="40" color="blue-grey darken-4 elevation-1" fixed>
      <v-row class="px-5" no-gutters>
        <!-- <v-spacer></v-spacer> -->
        <v-col align="center" lg="1" md="1" sm="2" xs="3" cols="3">
          <span class="font-weight-bold"><v-icon :color="socketStateColor" small>mdi-link-lock</v-icon> {{ blockHeader.blockNumber }}</span>
        </v-col>
        <v-col align="center" lg="1" md="1" sm="2" xs="3" cols="3">
          <span class="font-weight-normal"><v-icon :color="socketStateColor" small>mdi-alarm-check</v-icon>{{ formattedBlockTime }}</span>
        </v-col>
        <v-col align="center" lg="1" md="2" sm="2" xs="3" cols="3">
          <v-icon :color="socketStateColor" small>mdi-gas-station</v-icon>{{ formatGasPrice }} Gwei
        </v-col>
        <v-col align="center" lg="1" md="2" sm="2" xs="3" cols="3">
          <span class="font-weight-normal hoverable" @click.stop="reconnectCoinbase()"><v-icon :color="coinbaseStateColor" small>mdi-ethereum</v-icon>{{ formatEthPrice }}</span>
        </v-col>
      </v-row>
    </v-system-bar>
  </div>
</template>
<script>
import moment from 'moment'
// import BigNumber from 'bignumber.js'
// const gasnowUri = 'wss://www.gasnow.org/ws'
const coinbaseUri = 'wss://stream.binance.com:9443/ws'

export default {
  name: 'systembar',
  data() {
    return {
      blockHeader: {blockNumber: '---', blockTimeStamp: ''},
      gasPrices: null,
      ethPrice: '-',
      title: '',
      socketState: false,
      gasnowSocket: null,
      coinbaseSocket: null,
    }
  },
  created() {
    // if(!this.gasnowSocket)this.gasnowSocket = new WebSocket(gasnowUri)
    // if(this.gasnowSocket) {
    //   this.gasnowSocket.onopen = () => console.log('GasNow subscribed')
    //   this.gasnowSocket.onmessage = msg => {
    //     const data = JSON.parse(msg.data)
    //     this.gasPrices = {
    //       rapid: BigNumber(data.data.gasPrices.rapid).shiftedBy(-9).toNumber(),
    //       fast: BigNumber(data.data.gasPrices.fast).shiftedBy(-9).toNumber(),
    //     }
    //   }
    //   this.gasnowSocket.onclose = () => this.gasnowSocket = null
    // }
    this.reconnectCoinbase()
  },
  computed: {
      formattedBlockTime(){
        if(this.blockHeader.blockTimeStamp == '') return '---'
        else return moment(this.blockHeader.blockTimeStamp*1000).format('HH:mm:ss')
      },
      formatEthPrice() {
        if(this.ethPrice=='-')return '-'
        const cformat = new Intl.NumberFormat('en-US',{style:'currency',currency:'USD'})
        return cformat.format(this.ethPrice)
      },
      formatGasPrice() {
        if(this.gasPrices){
          return `${Number(this.gasPrices).toFixed(0)}`
        } else return '__'
      },
      socketStateColor(){
        if(this.socketState)return 'green'
        else return ''
      },
      gasnowStateColor(){
        if(this.gasnowSocket)return 'green'
        else return ''
      },
      coinbaseStateColor(){
        if(this.coinbaseSocket)return 'green'
        else return ''
      },
  },
  methods: {
    reconnectCoinbase() {
      if(!this.coinbaseSocket){
        this.coinbaseSocket = new WebSocket(coinbaseUri)
        console.log('Coinbase reconnected')
      }
      if(this.coinbaseSocket) {
        this.coinbaseSocket.onopen = () => {
          console.log('Coinbase subscribed')
          this.coinbaseSocket.send(JSON.stringify({
            "method": "SUBSCRIBE",
            "params":
            [
            "ethusdt@miniTicker",
            ],
            "id": 1
            }))
        }
      }
      this.coinbaseSocket.onmessage = msg => {
        const data = JSON.parse(msg.data)
        if(data.c)this.ethPrice = Number(data.c)
      }
      this.coinbaseSocket.onclose = () => {
        this.coinbaseSocket = null
        console.log("Coinbase disconnected")
      }
    }
  },
  sockets: {
      block(data) {
        if(this.socketState)this.socketState = true
        this.blockHeader = data
      },
      connect() {
        this.socketState = true
      },
      disconnect() {
        this.socketState = false
      },
      // ethprice(data) {
      //   this.ethPrice = data
      // },
      gasprice(data) {
        this.gasPrices = data
      }
  },
  watch: {
    ethPrice: function() {
      document.title = `${this.formatGasPrice} Gwei | ${this.formatEthPrice}`
    }
  }
}
</script>

<style scoped>
span.hoverable {
  cursor: pointer;
}
</style>