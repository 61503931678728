
import axios from 'axios'

//import moment from 'moment'

const weth = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'.toLowerCase()
const usds = ['0xdAC17F958D2ee523a2206206994597C13D831ec7'.toLowerCase(), //USDT
              '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'.toLowerCase(), //USDC
              '0x6B175474E89094C44Da98b954EedeAC495271d0F'.toLowerCase(), //DAI
              '0xa47c8bf37f92aBed4A126BDA807A7b7498661acD'.toLowerCase(), //UST
              '0x0000000000085d4780B73119b644AE5ecd22b376'.toLowerCase(), //TrueUSD
              '0x956F47F50A910163D8BF957Cf5846D573E7f87CA'.toLowerCase(), //FEI
              ]

async function graphQuery(query,dex) {
  //console.log(query)
  let endpoint = 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2'
  if(dex=="Sushi")endpoint = "https://api.thegraph.com/subgraphs/name/sushiswap/exchange"
  if(dex=="UniV3")endpoint = "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3"
  return axios.post(endpoint, {query: query})
    .then((res) => {
      return res.data.data
    }).catch( err => {
      console.log(err)
      return null
    })
}

async function getPool(address,dex) {
  const query = `
    {
      pool(id: "${address.toLowerCase()}"){
        token0 { id, symbol, name, decimals, derivedETH},
        token1 { id, symbol, name, decimals, derivedETH},
        totalValueLockedETH
      }
    }
  `
  return graphQuery(query,dex).then(res => { 
    const pool = res.pool
    pool.reserveETH = pool.totalValueLockedETH
    return pool
  })
}

async function getPair(address,dex){
  if(dex=="UniV3"){
    return getPool(address,dex)
  }
  const query = `
    {
      pair(id: "${address.toLowerCase()}"){
        token0 { id, symbol, name, decimals, derivedETH},
        token1 { id, symbol, name, decimals, derivedETH},
        reserveETH,
      },
      
    }
  `
  return graphQuery(query,dex).then(res => { return res.pair })
}


function getLast60MTime(timestamp){
  let dtTime = null
  if(timestamp)dtTime = new Date(timestamp)
  else dtTime = new Date()
  const minute = dtTime.getMinutes()
  let newMinute = 0
  let newHour = 0
  if(minute<=30)newMinute = 30
  if(minute>30){
    newMinute = 0
    newHour += 1
  }
  dtTime.setHours(newHour)
  dtTime.setMinutes(newMinute)
  dtTime.setSeconds(0)
  dtTime.setMilliseconds(0)
  return dtTime.getTime()
}

async function rpullOHLC(timestamp,pool,dex) {
  const params = {
    timestamp: timestamp,
    pool: pool,
    dex: dex
  }
  return await axios.get(`${process.env.VUE_APP_MAIN_URL}/ohlc/graph`,{params}).then(res=>{
    return res.data.data
  })
}

async function rpullCexOHLC(timestamp,pair,interval,cex) {
  const params = {
    timestamp: timestamp,
    pair: pair,
    interval: interval
  }
  return await axios.get(`${process.env.VUE_APP_MAIN_URL}/ohlc/${cex}`,{params}).then(res=>{
    const tmp = res.data.data
    return tmp.sort((a,b) => a.time - b.time)
  }).catch( () => { return null })
}

export { getPair, getLast60MTime, rpullOHLC, rpullCexOHLC, weth, usds }