<template>
    <v-data-table
      height="400"
      dark
      dense
      :loading="trends[0]==null"
      loading-text="waiting for data..."
      :headers="trendHeaders"
      :items="trends"
      :hide-default-footer="true"
      :disable-pagination="true"
      class="grey--text text--lighten-1 text-caption py-6"
      mobile-breakpoint="0"
    >
      <!-- <template v-slot:top>
        <v-row class="px-4 pt-0">
          <v-col align="center" class="text-h6 grey--text font-weight-bold">Heat Rank</v-col>
        </v-row>
      </template> -->
      <template v-slot:item.symbol="{ item }">
        <span class="" @click.stop="eventEmitter('TxTable',item.address)">
        <img :src="item.icon" style="width: 14px; height: 14px">
        <!-- <a :href="`https://etherscan.io/token/${item.address}`" target="_blank"> -->
          {{ item.symbol.slice(0,8) }}
        <!-- </a> -->
        </span>
      </template>
      <template v-slot:item.change="{ item }">
        <span :class="priceChangeClr(item.change)">{{ formatImpact(item.change) }}</span>
      </template>
      <!-- <template v-slot:footer>
        <v-row class="pt-3 pb-0 px-5">
          <v-spacer></v-spacer>
          <v-col cols="6">
              <v-text-field
                    v-model="searchtrend"
                    prepend-icon="mdi-magnify"
                    label=""
                    single-line
                    hide-details
                    dense
                    clearable
                  ></v-text-field>
          </v-col>
        </v-row>
            </template> -->
      
    </v-data-table>
</template>

<script>
import axios from 'axios'
import { EventHub } from './eventhub'
import BigNumber from 'bignumber.js'
export default {
  name: 'trendtable',
  data() {
    return {
      searchtrend: '',
      trends: [],
      page: 1,
      pageCount: 0,
      trendsOld: []
    }
  },
  computed: {
    trendHeaders() {
      return [
        { text: 'Trending 1H', value: 'symbol', sortable: false},
        //{ text: 'Freq', value: 'freq', sortable: false, align: 'end'},
        { text: '1H%', value: 'change', filterable: false, sortable: false, align: 'end'},
        { text: 'Freq', value: 'freq', filterable: false, sortable: false, align: 'end'},
      ]}
  },
  methods: {
    formatImpact(impact) {
        if(Math.abs(Number(impact))>100000000000000)return "\u221e"
        else return this.formatFloat(Number(impact),2)
      },
    formatFloat(number,dec=2) {
        try { number = Number(number) }
        catch { number = 0 }
        if(number===0)return '-'
        else if(Math.abs(number)<1){
          if(number.toString().includes('e')){
            const tmp = number.toFixed(20).replace(/\.?0+$/,"")
            const last00 = tmp.lastIndexOf('00')
            return '0._..'+tmp.slice(last00,last00+6)+'-'+last00
          }
          else return number.toFixed(dec)
        } else {
          let res = number
          if(number>=1000000000000)res=BigNumber(number).shiftedBy(-12).toNumber().toFixed(2)+'t'
          else if(number>=1000000000)res=BigNumber(number).shiftedBy(-9).toNumber().toFixed(2)+'b'
          else if(number>=1000000)res=BigNumber(number).shiftedBy(-6).toNumber().toFixed(2)+'m'
          else if(number>=10000)res=BigNumber(number).shiftedBy(-3).toNumber().toFixed(2)+'k'
          else if(number>=1)res=Number(number).toFixed(2)
          else res = Number(number).toFixed(2)
          return res
        }
      },
    priceChangeClr (val) {
        if(Number(val)>0)return 'green--text text--lighten-0'
        if(Number(val)<0)return 'red--text text--lighten-1'
        return ''
      },
    initTrendData() {
        axios.get(`${process.env.VUE_APP_MAIN_URL}/swap/trend`)
          .then(res => {
            this.trends = res.data.data
            this.eventEmitter("TrendTable1h",this.trends)
          })
          .catch(err=>console.log('err',err))
      },
    eventEmitter(eventName,payLoad) {
      EventHub.$emit(eventName,payLoad)
    },
  },
  mounted() {
    this.initTrendData()
  },
  created() {
    EventHub.$on('TrendTable', data => {
        if(this.searchtrend != data)this.searchtrend = data
        else this.searchtrend = ''
      })
  },
  sockets: {
    trend(data) {
      this.trends = data
      this.eventEmitter("TrendTable1h",this.trends)
    }
  },
}
</script>

<style scoped>
img {
  filter: brightness(70%);
}
a, a:link, a:visited {
  text-decoration: none;
  color: #60acdf;
}
a:hover {
  text-decoration: underline;
  color: #60acdf;
}
</style>