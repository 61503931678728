<template>
    <v-data-table
      height="400"
      dark
      dense
      :loading="trends[0]==null"
      loading-text="waiting for data..."
      :headers="trendHeaders"
      :items="trends"
      :hide-default-footer="true"
      :disable-pagination="true"
      class="grey--text text--lighten-1 text-caption my-3 pb-6"
      mobile-breakpoint="0"
    >
      <!-- <template v-slot:top>
        <v-row class="px-4 pt-0">
          <v-col align="center" class="text-h7 yellow--text font-weight-bold">Trending 10min</v-col>
        </v-row>
      </template> -->
      <template v-slot:item.symbol="{ item }">
        <span class="" @click.stop="eventEmitter('TxTable',item.address)">
        <img :src="item.icon" style="width: 14px; height: 14px">
        <!-- <a :href="`https://etherscan.io/token/${item.address}`" target="_blank"> -->
          {{ item.symbol.slice(0,8) }}
        <!-- </a> -->
        </span>
      </template>
      <template v-slot:item.change="{ item }">
        <span :class="priceChangeClr(item.change)">{{ item.change }}</span>
      </template>

      <template v-slot:item.freq="{ item }">
        <!-- <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"> {{ item.freq }}</span>
          </template> -->
          <span> {{ item.freq }}</span><span class="text-caption"><sub>/{{ item.h1 }}</sub></span>
        <!-- </v-tooltip> -->
      </template>
      <!-- <template v-slot:footer>
        <v-row class="pt-3 pb-0 px-5">
          <v-spacer></v-spacer>
          <v-col cols="6">
              <v-text-field
                    v-model="searchtrend"
                    prepend-icon="mdi-magnify"
                    label=""
                    single-line
                    hide-details
                    dense
                    clearable
                  ></v-text-field>
          </v-col>
        </v-row>
      </template> -->
      
    </v-data-table>
</template>

<script>
import axios from 'axios'
import { EventHub } from './eventhub'

export default {
  name: 'trendtable10',
  data() {
    return {
      trends: [],
      trends1h: [],
      page: 1,
      pageCount: 0,
      trendsOld: []
    }
  },
  computed: {
    trendHeaders() {
      return [
        { text: 'Trending 10m', value: 'symbol', sortable: false},
        //{ text: 'Freq', value: 'freq', sortable: false, align: 'end'},
        { text: '10m%', value: 'change', filterable: false, sortable: false, align: 'end'},
        { text: 'Freq', value: 'freq', filterable: false, sortable: false, align: 'start'},
      ]}
  },
  methods: {
    findTrend1h(address){
      const tmpAddresses = this.trends1h.map( (trend) =>{
                              return trend.address
                            })
      const trend1h = this.trends1h[tmpAddresses.indexOf(address)]
      //console.log(trend1h)
      if(trend1h)return trend1h.freq
      else return '-'
    },
    priceChangeClr (val) {
        if(Number(val)>0)return 'green--text text--lighten-0'
        if(Number(val)<0)return 'red--text text--lighten-1'
        return ''
      },
    initTrendData() {
        axios.get(`${process.env.VUE_APP_MAIN_URL}/swap/trend10`)
          .then(res => {
            this.trends = res.data.data
            axios.get(`${process.env.VUE_APP_MAIN_URL}/swap/trend`)
            .then(res => {
              this.trends1h = res.data.data
              this.trends.map(trend =>{
                trend.h1 = this.findTrend1h(trend.address)
                //if(trend.h1<trend.freq)trend.h1 = trend.freq
              })
            })
            .catch(err=>console.log('err',err))
          })
          .catch(err=>console.log('err',err))
      },
    
    eventEmitter(eventName,payLoad) {
      EventHub.$emit(eventName,payLoad)
    },
  },
  created() {
    this.initTrendData()
    EventHub.$on('TrendTable1h', data => {
        this.trends1h = data
        this.trends.map(trend =>{
          trend.h1 = this.findTrend1h(trend.address)
          //if(trend.h1<trend.freq)trend.h1 = trend.freq
        })
      })
  },
  sockets: {
    trend10(data) {
      this.trends = data
      this.trends.map(trend =>{
        trend.h1 = this.findTrend1h(trend.address)
        //if(trend.h1<trend.freq)trend.h1 = trend.freq
      })
    },
    // trend(data){
    //   this.trends1h = data
    //   this.trends.map(trend =>{
    //     trend.h1 = this.findTrend1h(trend.address)
    //     //if(trend.h1<trend.freq)trend.h1 = trend.freq
    //   })
    // }
  },
}
</script>

<style scoped>
img {
  filter: brightness(70%);
}
a, a:link, a:visited {
  text-decoration: none;
  color: #60acdf;
}
a:hover {
  text-decoration: underline;
  color: #60acdf;
}
</style>