<template>
  <div>
  <v-bottom-sheet v-model="btmsheet" scrollable :hide-overlay="true">
    <!-- <v-sheet
      class="text-center"
      height="400px"
    > -->
    <v-card class="pa-5">
      <v-card-text style="height: 360px;">
      <!-- <v-container class="pa-5"> -->
      <v-row align="center">
        
        <!--        
        <v-col align="center">
          <v-card align="center" flat color="grey lighten-4" max-width="460" class="pa-5" id="tvchart">
          </v-card>
        </v-col>
        -->
        <v-col align-self="start">
          <v-card align="left" flat color="grey lighten-4" max-width="460">
            <v-row no-gutters class="mx-4 pt-3">
               <v-col cols="2" class="pt-1">
                <img style="height: 50px; width: 50px;" :src="tokenInfo.icon">
              </v-col>
            <v-col class="pl-1">
              <span class="text-h5 font-weight-bold grey--text text--darken-2">{{ tokenInfo.name }} | {{ tokenInfo.symbol }}</span>
              <div>
              <span class="pr-1 v-icon">
                <a v-if="tokenInfo.pairDextools" :href="tokenInfo.pairDextools" target="_blank">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <img v-bind="attrs" v-on="on" style="height:20px; width=20px" src="/dextools.png" alt="Dextools">
                    </template>
                    <span>Dextools - {{tokenInfo.dex}}</span>
                  </v-tooltip>
                </a>
                <img v-else class="disabled" style="height:20px; width=20px" src="/dextools.png" alt="Dextools">
              </span>
              <span class="px-1 v-icon">
                <a v-if="tokenInfo.coingecko" :href="`https://www.coingecko.com/en/coins/${tokenInfo.coingecko}`" target="_blank">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <img v-bind="attrs" v-on="on" style="height:20px; width=20px" src="/coingecko.png" alt="Coingecko">
                    </template>
                    <span>Coingecko</span>
                  </v-tooltip>
                </a>
                <img v-else class="disabled" style="height:20px; width=20px" src="/coingecko.png" alt="Coingecko">
              </span>
              <span class="px-1">
                <a v-if="tokenInfo.web" :href="tokenInfo.web" target="_blank">
                  <v-tooltip bottom >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="px-0 py-0" color="light-blue lighten-1">mdi-web</v-icon>
                    </template>
                    <span>{{ tokenInfo.web }}</span>
                  </v-tooltip>
                </a>
                <v-icon v-else class="px-0 py-0" color="grey lighten-1">mdi-web</v-icon>
              </span>
              <span class="px-1 v-icon">
                <a v-if="tokenInfo.twitter.handle" :href="`https://twitter.com/${tokenInfo.twitter.handle}`" target="_blank">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <!-- <img v-bind="attrs" v-on="on" style="height:20px; width=20px" src="/twitter.png" alt="Twitter"> -->
                      <v-icon v-bind="attrs" v-on="on" class="px-0 py-0" color="light-blue">mdi-twitter</v-icon>
                    </template>
                    <span>{{ formatTwitter }}</span>
                  </v-tooltip>
                </a>
                <v-icon v-else class="px-0 py-0" color="grey lighten-1">mdi-twitter</v-icon>
              </span>
              <span class="px-1 v-icon">
                <a :href="`/stats/tokentx/${tokenInfo.address}`" target="_blank">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <img v-bind="attrs" v-on="on" style="height:20px; width=20px" src="/favicon.ico" alt="Token Txs">
                    </template>
                    <span>See token txs</span>
                  </v-tooltip>
                </a>
              </span>
            </div>
            
            </v-col>
            <div>
              <span class="text-caption px-2">
              <a :href="`https://etherscan.io/token/${tokenInfo.address}`" target="_blank" id="tokenAddressCopy">
                {{ tokenInfo.address }}
              </a>
              <v-btn icon x-small @click.stop="copyText('tokenAddressCopy')">
                <v-icon x-small>mdi-content-copy</v-icon>
              </v-btn>
            </span>
            </div>
            </v-row>
           
            <!-- <div class="d-flex flex-no-wrap justify-space-between"> -->
            
            
            
            
            <v-card-text>
              <!-- <v-container class="ma-5"> -->
                <v-row class="">
              <!-- <span class="text-subtitle1 grey--text text--darken-3 font-weight-regular">Last Price: </span> -->
              <span class="pl-5 pr-2 text-h4 grey--text text--darken-2 font-weight-bold">{{ formatFloat(tokenInfo.lastPrice) }} ETH</span>
              <span class="pt-1"><v-btn icon small elevation="1" tile :loading="chartBtn" @click.stop="reloadRpullChart()"><v-icon color="blue">mdi-chart-line</v-icon></v-btn></span>
            </v-row>
            <v-row no-gutters class="pt-5 px-2">
              <v-col cols="3" class="my-0 py-0">
                <span class="text-subtitle grey--text text--darken-2 font-weight-regular">Pool: </span>
              </v-col>
              <v-col>
                <span v-if="tokenInfo.pool" class="text-subtitle grey--text text--darken-2 font-weight-regular">
                  {{tokenInfo.dex}} | 
                  <a :href="`https://etherscan.io/token/${tokenInfo.pool}`" target="_blank" id="poolAddressCopy">
                    {{tokenInfo.pair}}
                  </a>
                  <v-btn icon x-small @click.stop="copyHref('poolAddressCopy')">
                      <v-icon x-small>mdi-content-copy</v-icon>
                  </v-btn>
                </span>
              </v-col>
            </v-row>
            <v-row no-gutters class="px-2">
              <v-col cols="3" class="my-0 py-0">
                <span class="text-subtitle grey--text text--darken-2 font-weight-regular">Reserve: </span>
              </v-col>
              <v-col>
                <span class="text-subtitle grey--text text--darken-2 font-weight-bold">{{formatFloat(tokenInfo.poolSize)}} ETH</span>
              </v-col>
            </v-row>
            <v-row no-gutters class="px-2">
              <v-col cols="3" class="my-0 py-0">
                <span class="text-subtitle grey--text text--darken-2 font-weight-regular">Holders: </span>
              </v-col>
              <v-col>
                <span class="text-subtitle grey--text text--darken-2 font-weight-bold">{{tokenInfo.holders}}</span>
              </v-col>
            </v-row>
            <v-row no-gutters class="px-2 pb-2">
              <v-col cols="3" class="my-0 py-0">
                <span class="text-subtitle grey--text text--darken-2 font-weight-regular">Decimals: </span>
              </v-col>
              <v-col>
                <span class="text-subtitle grey--text text--darken-2 font-weight-bold">{{tokenInfo.decimals}}</span>
              </v-col>
            </v-row>
            <!-- check Honeypot -->
            <v-divider></v-divider>
            <v-row no-gutters class="px-0 py-2">
              <v-col class="my-0 py-0">
                <span class="text-subtitle grey--text text--darken-2 font-weight-regular">
                  <v-btn
                    class="ma-0"
                    small
                    outlined
                    color="primary"
                    :loading="honeypotBtn"
                    @click.stop="chekcHoneypot(tokenInfo.address)">
                      Check Honeypot
                  </v-btn>
                </span>
              </v-col>
            </v-row>
            <v-row no-gutters class="px-2">
              <v-col cols="4" class="my-0 py-0">
                <span class="text-subtitle grey--text text--darken-2 font-weight-regular">Honeypot: </span>
                <span :class="honeypotTxtClr">{{tokenInfo.isHoneyPot}}</span>
              </v-col>
              <v-col cols="4" class="my-0 py-0">
                <span class="text-subtitle grey--text text--darken-2 font-weight-regular">BuyTax: </span>
                <span :class="honeypotTaxClr">{{tokenInfo.buyTax}}%</span>
              </v-col>
              <v-col cols="4" class="my-0 py-0">
                <span class="text-subtitle grey--text text--darken-2 font-weight-regular">SellTax: </span>
                <span :class="honeypotTaxClr">{{tokenInfo.sellTax}}%</span>
              </v-col>
            </v-row>
            </v-card-text>
          </v-card>
          <!-- end of token card -->
          
              
        </v-col>
        <v-col align-self="start">
          <v-simple-table dense height="320">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Exchanges</th>
                  <th class="text-center">Vol(USD)</th>
                  <th class="text-center">Vol%</th>
                  <th class="text-center">Price</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in tokenInfo.exchanges" :key="item.name">
                  <td class="text-center">
                    <a :href="item.url" target="_blank">{{item.name}}</a>
                    <v-menu
                      v-if="knownCex.some(x => item.name.toLowerCase().replace('.','').includes(x))"
                      open-on-hover
                      offset-x
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon x-small color="blue" tile elevation="1"><v-icon small>mdi-chart-line</v-icon></v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="(pair,index) in item.pair" :key="index" link>
                          <v-list-item-title @click.stop="loadCexChart(pair,knownCex.filter(x => item.name.toLowerCase().replace('.','').includes(x)))">{{pair.replace('_','/')}}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    
                  </td>
                  <td class="text-center text-caption">{{Number(formatFloat(item.volume)).toLocaleString({style: 'currency'})}}</td>
                  <td class="text-center">{{item.pie.toFixed(2)}}</td>
                  <td class="text-center">{{formatFloat(item.price)}}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
  </div>
</template>

<script>
import { EventHub } from './eventhub'
import { getPair, rpullOHLC, rpullCexOHLC } from './ohlcloader'
import axios from 'axios'
import moment from 'moment'

async function coinInfo(token) {
  const res = await axios.get(`https://api.coingecko.com/api/v3/coins/ethereum/contract/${token.toLowerCase()}`)
                      .then(res=>{ return res.data})
                      .catch(()=>{
                        console.log('CoinGecko API Error')
                        return null
                        })
  if(!res)return null

  const exchanges = {}
  let volTotal = 0
  //count volTotal
  res.tickers.forEach( item =>{
    volTotal += Number(item.volume)
  })
  //entry data
  res.tickers.forEach( item =>{
    if(!exchanges[item.market.name]){
      exchanges[item.market.name] = {
        url: item.trade_url,
        volume: Number(item.volume),
        price: item.converted_last.eth,
        pair: [item.base+'_'+item.target],
        stale: item.is_stale,
        anomaly: item.is_anomaly,
        pie: item.volume/volTotal*100
      }
    } else {
      if(item.volume>exchanges[item.market.name].volume){
        exchanges[item.market.name].url = item.trade_url
      }
      exchanges[item.market.name].pair.push(item.base+'_'+item.target)
      exchanges[item.market.name].volume = exchanges[item.market.name].volume+Number(item.volume)
      exchanges[item.market.name].price = item.converted_last.eth
      exchanges[item.market.name].pie = exchanges[item.market.name].volume/volTotal*100
    }
  })
  
  let finalArr = []
  Object.entries(exchanges).forEach(([key,val]) => {
    val.name = key
    finalArr.push(val)
  })

  //sort it
  if(finalArr.length>0)finalArr = finalArr.sort((a,b) => b.pie - a.pie)
  return {
    exchanges: finalArr,
    id: res.id,
    symbol: res.symbol,
    name: res.name,
    web: res.links.homepage[0],
    twitter: res.links.twitter_screen_name,
    icon: res.image.small,
    twitterFollowers: res.community_data.twitter_followers
  }
}

import { createChart } from 'lightweight-charts'

function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
   }

async function drawChart() {
  let candleSeries = null
  let counter = 0
  while((!document.getElementById("tvchart") || candleSeries==null) && counter<=3){
    counter+=1
    await sleep(500)
    try{
      console.log('Loading chart...')
      const chart = createChart("tvchart", { width: 400, height: 320 })
      chart.applyOptions({
        watermark: {
            color: 'rgba(218, 223, 225, 1)',
            visible: true,
            text: "click chart icon to load",
            fontSize: 24,
            horzAlign: 'center',
            vertAlign: 'top'
          },
        crosshair: {
          mode: 0
        },
        priceFormat: {
          type: 'volume',
          precision: 8,
        },
        localization: {
          timeFormatter: time => {
            return moment(time*1000).format("MMMDD HH:mm")
          },
          priceFormatter: price => {
            if(price.toString().includes('e')){
              return price.toFixed(20).replace(/\.?0+$/,"")
            }
            else {
              if(price<1)return price.toFixed(8)
              else return price.toFixed(2)
            }
          }
        },
        timeScale: {
          tickMarkFormatter: time => {
            return moment(time*1000).format("HH:mm")
          }
        },
        handleScale: {
          axisPressedMouseMove: {
            time: true,
            price: false
          }
        }
      })
      candleSeries = chart.addCandlestickSeries()
      return {chart: chart, candleSeries: candleSeries}
    } catch(err){ continue }
    
  }
  
}

export default {
  name: 'tokenbottom',

  data() {
    return {
      chartsLib: null,
      // chartData: [
      // ],
      
      chartOptions: {
        title: 'M30',
        legend: 'none',
        width: 500,
        height: 450,
        bar: { groupWidth: '100%' }, // Remove space between bars.
        candlestick: {
          fallingColor: { strokeWidth: 1, fill: '#a52714' }, // red
          risingColor: { strokeWidth: 1, fill: '#0f9d58' }   // green
        }

      },
      tokenInfo: {
        address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
        symbol: "ETH",
        decimals: 0,
        lastPrice: 0,
        icon: "https://etherscan.io/images/main/empty-token.png",
        name: "Ethereum",
        twitter: { handle: "", follower: 0},
        web: "",
        coingecko: "ethereum",
        dex: "UniV2",
        pair: "",
        pairDextools: "",
        pool: "",
        poolSize: "",
        exchanges: [],
        holders: "-",
        isHoneyPot: "--",
        buyTax: "--",
        sellTax: "--"
      },
      btmsheet: false,
      ohlc: null,
      chart: null,
      chartBtn: false,
      candleSeries: null,
      chartCreated: false,
      trends: [],
      page: 1,
      pageCount: 0,
      
      alignments: [
        'start',
        'center',
        'end',
      ],
      knownCex: [
        'binance',
        'gateio',
        'kucoin',
        'bittrex',
        'okex',
        'ftx',
        'upbit',
        'coinbase',
        'hotbit',
        'huobi',
        'hitbtc',
        'bkex',
        'hoocom'],
      honeypotTxtClr: 'text-subtitle grey--text text--darken-2 font-weight-bold',
      honeypotTaxClr: 'text-subtitle grey--text text--darken-1 font-weight-bold',
      honeypotBtn: false
    }
  },
  methods: {
    async chekcHoneypot(tokenAddr) {
      console.log("checking honeypot...")
      this.honeypotBtn=true
      await axios.get(`https://aywt3wreda.execute-api.eu-west-1.amazonaws.com/default/IsHoneypot?chain=eth&token=${tokenAddr}`)
        .then(res => {
          this.honeypotBtn=false
          const honeypotCheck = res.data
          if(honeypotCheck.error==null){
            this.tokenInfo.isHoneyPot = honeypotCheck.IsHoneypot
            this.tokenInfo.buyTax = honeypotCheck.BuyTax
            this.tokenInfo.sellTax = honeypotCheck.SellTax
            if(honeypotCheck.IsHoneypot==false)
              this.honeypotTxtClr = 'text-subtitle green--text text--darken-2 font-weight-bold'
            else this.honeypotTxtClr = 'text-subtitle red--text text--darken-1 font-weight-bold'
            if(Number(honeypotCheck.BuyTax)>0 || Number(honeypotCheck.SellTax)>0)
              this.honeypotTaxClr = 'text-subtitle orange--text text--darken-1 font-weight-bold'
            else this.honeypotTaxClr = 'text-subtitle green--text text--darken-2 font-weight-bold'
          }
        })
        .catch(err=>console.log('honeypotCheck err:',err))
    },
    coingeckoToken(tokenAddr) {
      if(tokenAddr==='0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2')this.tokenInfo.exchanges = []
      else {
        coinInfo(tokenAddr).then(res=>{
          this.tokenInfo.exchanges = res.exchanges
          if(this.tokenInfo.name=="" || this.tokenInfo.name=="Ethereum")this.tokenInfo.name = res.name
          this.tokenInfo.icon = res.icon
          this.tokenInfo.twitter.handle = res.twitter
          this.tokenInfo.twitter.follower = res.twitterFollowers
          this.tokenInfo.coingecko = res.id
          this.tokenInfo.web = res.web
        }).catch(()=> {
          this.tokenInfo.exchanges = []
          this.tokenInfo.icon = "https://etherscan.io/images/main/empty-token.png"
          this.tokenInfo.twitter.handle = ""
          this.tokenInfo.twitter.follower = ""
          this.tokenInfo.coingecko = ""
          this.tokenInfo.web = ""
        })
      }
    },
    graphGetPair(pairAddr,tokenAddr,dex) {
      getPair(pairAddr,dex).then(res => {
        this.tokenInfo.pair = res.token0.symbol+'/'+res.token1.symbol
        let lastPrice = 0
        
        if(res.token0.id===tokenAddr.toLowerCase()){
          this.tokenInfo.name=res.token0.name
          this.tokenInfo.decimals = res.token0.decimals
          lastPrice = res.token0.derivedETH
        }
        if(res.token1.id===tokenAddr.toLowerCase()){
          this.tokenInfo.name=res.token1.name
          this.tokenInfo.decimals = res.token1.decimals
          lastPrice = res.token1.derivedETH
        }
        
        //console.log('lastprice',lastPrice)
        this.tokenInfo.poolSize = Number(res.reserveETH)/2
        this.tokenInfo.lastPrice = Number(lastPrice)
      }).catch(()=>console.log('getPair err'))
    },
    getTwoDaysTime(){
      let timeMark = new Date(new Date().getTime() - 20*86400000)
      //const twodays = timeMark.getUTCDate()-2
      //timeMark.setUTCDate(twodays)
      //timeMark.setUTCHours(0)
      timeMark.setMinutes(0)
      timeMark.setSeconds(0)
      timeMark.setMilliseconds(0)
      return timeMark
    },
    loadCexChart(pair,cex){
      this.candleSeries.setData([])
      this.chart.applyOptions({
              watermark: {
                text: "Loading...",
              }
            })
      rpullCexOHLC(this.getTwoDaysTime().getTime(),pair,'30m',cex.toString()).then(res=>{
        if(res && res.length>0){
          try {
            this.candleSeries.setData(res)
            this.chart.applyOptions({
              watermark: {
                text: pair.replace('_','/')+' - '+cex.toString().toUpperCase(),
              }
            })
          }
          catch (err) {
            console.log('chart err',err)
          }
        }
      }).catch(err => console.log('Cex data failed',err))
    },
    reloadRpullChart(){
      this.candleSeries.setData([])
      this.chart.applyOptions({
              watermark: {
                text: "Loading...",
              }
            })
      this.chartBtn=true
      rpullOHLC(this.getTwoDaysTime().getTime(),this.tokenInfo.pool,this.tokenInfo.dex).then(res=>{
        this.chartBtn=false
        this.ohlc=res.ohlc
        this.candleSeries.setData(this.ohlc)
        this.chart.applyOptions({
          watermark: {
            text: `${res.pairName} - ${this.tokenInfo.dex}`,
          }
        })
      })
    },
    formatFloat(number) {
      try { number = Number(number) }
      catch { number = 0 }
      if(number===0)return '-'
      else if(number<1){
        if(number.toString().includes('e')){
          const tmp = number.toFixed(20).replace(/\.?0+$/,"")
          const last00 = tmp.lastIndexOf('00')
          return '0._..'+tmp.slice(last00,last00+6)
        }
        else return number.toFixed(8)
      } else return number.toFixed(2)
    },
    copyText(elementName) {
      const copyText = document.getElementById(elementName).text
      navigator.clipboard.writeText(copyText.trim()).then(()=>{
        alert('Copied! '+ copyText)
      }).catch(()=>console.log('problem copying.'))
    },
    copyHref(elementName) {
      let copyText = document.getElementById(elementName).href
      copyText = copyText.slice(copyText.lastIndexOf('/')+1).trim()
      navigator.clipboard.writeText(copyText).then(()=>{
        alert('Copied! '+ copyText)
      }).catch(()=>console.log('problem copying.'))
    }
  },
  mounted() {
    EventHub.$on('TokenBottom', data => {
      if(data.tokenAddr == '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2')return null
      this.btmsheet = true,
      
      this.graphGetPair(data.pair,data.tokenAddr,data.dex)

      this.tokenInfo.dex = data.dex
      this.tokenInfo.symbol = data.symbol
      this.tokenInfo.address = data.tokenAddr
      this.tokenInfo.pool = data.pair
      if(["UniV2","UniV3","Sushi","Shiba"].includes(data.dex))this.tokenInfo.pairDextools = "https://dexscreener.com/ethereum/"+data.pair

      axios.get(`${process.env.VUE_APP_MAIN_URL}/token/holder?id=${data.tokenAddr}`).then(res=>{
        this.tokenInfo.holders = res.data.data
      })
      this.coingeckoToken(data.tokenAddr)
      
      //this.tokenInfo.pair = data.pair
      if(["UniV2","UniV3","Sushi"].includes(data.dex)){
        if(this.chartCreated==false && this.chart==null){
          drawChart().then(res => {
            this.candleSeries = res.candleSeries
            this.chartCreated = true
            this.chart = res.chart
          })
          this.chartCreated = true
        }else{
          this.candleSeries.setData([])
          this.chart.applyOptions({
            watermark: {
              text: "click chart icon to load",
            }
          })
        }
        // rpullOHLC(this.getTwoDaysTime().getTime(),data.pair,data.dex).then(res=>{
        //   this.ohlc=res.ohlc
        //   this.candleSeries.setData(this.ohlc)
        //   this.chart.applyOptions({
        //     watermark: {
        //       text: `${res.pairName} - ${data.dex}`,
        //     }
        //   })
        // })
      }
    })
  },
  computed: {
    formatTwitter() {
        let follower = Number(this.tokenInfo.twitter.follower)
        let handle = '@'+this.tokenInfo.twitter.handle
        if(this.tokenInfo.twitter.handle=="")return "N/A"
        let res = follower+' follower'
        //if(amt>=1000)res=BigNumber(amt).shiftedBy(-3).toNumber().toFixed(4)+'k'
        if(follower>=1000000)res=(Number(follower)/1000000).toFixed(1)+'M follower'
        else if(follower>=1000)res=(Number(follower)/1000).toFixed(1)+'K follower'
        else if(follower==0)res = "N/A"
        return `${handle} | ${res}`
    },
  },
  watch: {
    btmsheet: function () {
      if(this.btmsheet==false){
        this.tokenInfo = {
          address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
          symbol: "ETH",
          decimals: 0,
          lastPrice: 0,
          icon: "https://etherscan.io/images/main/empty-token.png",
          name: "Ethereum",
          twitter: { handle: "", follower: 0},
          web: "",
          coingecko: "ethereum",
          dex: "UniV2",
          pair: "",
          pairDextools: "",
          pool: "",
          poolSize: "",
          exchanges: [],
          holders: "-",
          isHoneyPot: "--",
          buyTax: "--",
          sellTax: '--'
        }
        this.honeypotTxtClr = ""
        this.honeypotTaxClr = ""
        this.candleSeries.setData([])
        this.chart.applyOptions({
            watermark: {
              text: "",
            }
          })
      }
    }
  }
}
</script>

<style scoped>
img.disabled {
  filter: grayscale(100%);
}
a, a:link, a:visited {
  text-decoration: none;
  color: #3498db;
}
a:hover {
  text-decoration: underline;
  color: #3498db;
}
</style>
