<template>
  <div>
  <v-dialog v-model="card" hide-overlay width="430">
    <!-- <v-sheet
      class="text-center"
      height="400px"
    > -->
    <v-card dark class="grey darken-3">
      <!-- <span class="blue lighten-0"> -->
        <v-card-title class="text-h5 grey--text text--lighten-2 blue-grey darken-3">
          <v-icon left color="grey lighten-2">{{title}}</v-icon>
          Balances</v-card-title>
        <v-card-subtitle class="blue-grey darken-3">
          {{address}}
          <a :href="`https://etherscan.io/address/${address}`" target="_blank">
          <v-icon dense small>mdi-open-in-new</v-icon>
          </a>
          <v-icon v-if="title.includes('mdi-wallet')" dense small @click="closeAndSearch(address)">mdi-magnify</v-icon>
        </v-card-subtitle>
      <!-- </span> -->
      <v-card-text>
        <v-list class="grey darken-3">
          <v-list-item v-for="token in tokens" :key="token.index">
              <v-list-item-content>
                <span v-if="token.symbol=='ETH'" class="grey--text text--lighten-3">
                  <v-icon small>mdi-ethereum</v-icon>
                  <span class="font-weight-bold">{{token.symbol}}</span>
                  {{ toLocale(token.balance) }}
                </span>
                <span v-else class="grey--text text--lighten-3">
                  <span class="pr-1">
                    <img class="icon" :src="token.icon"></span>
                    <span class="font-weight-bold">{{token.symbol}}</span>
                    {{toLocale(token.balance)}}
                  <!-- <v-img max-width="14" max-height="14" :src="token.icon"></v-img>
                  <div>{{token.symbol}} balance: {{toLocale(token.balance)}}</div> -->
                </span>
              </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="card=false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import { EventHub } from './eventhub'
import { weth } from './ohlcloader'
import axios from 'axios'

const usdt = '0xdAC17F958D2ee523a2206206994597C13D831ec7'.toLowerCase() //USDT
const usdc = '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'.toLowerCase() //USDC

export default {
  name: 'waletbottom',

  data() {
    return {
      card: false,
      title: "",
      address: "",
      tokens: [],
    }
  },
  mounted() {
    EventHub.$on('WalletDialog', async data => {
      this.card = true
      this.title = data.title
      this.address = data.address
      
      this.tokens = [
        {
          symbol: data.token1.symbol,
          address: data.token1.address,
          decimals: data.token1.decimals,
          icon: data.token1.icon,
          balance: 0
        },
        {
          symbol: data.token2.symbol,
          address: data.token2.address,
          decimals: data.token2.decimals,
          icon: data.token2.icon,
          balance: 0
        }
      ]

      if(![data.token1.address.toLowerCase(),data.token2.address.toLowerCase()].includes(usdc)){
        this.tokens.push({
          symbol: "USDC",
          address: usdc,
          decimals: 6,
          icon: "https://etherscan.io/token/images/centre-usdc_28.png",
          balance: 0
        })
      }

      if(![data.token1.address.toLowerCase(),data.token2.address.toLowerCase()].includes(usdt)){
        this.tokens.push({
          symbol: "USDT",
          address: usdt,
          decimals: 6,
          icon: "https://etherscan.io/token/images/tether_32.png",
          balance: 0
        })
      }

      if(![data.token1.address.toLowerCase(),data.token2.address.toLowerCase()].includes(weth)){
        this.tokens.unshift({
          symbol: "ETH",
          address: weth,
          decimals: 18,
          icon: "",
          balance: 0
        })
      }
      this.tokens.forEach(async (item, i) => {
        this.tokens[i].balance = await this.findBalance(item.address,item.decimals,this.address)
      })
    })
  },
  methods: {
    async findBalance(tokenAddress,decimals,wallet) {
      let bal, params
      if(tokenAddress.toLowerCase()==weth){
        //bal = await axios.get(`https://dex.rugpulled.club/eth/ethbal?wallet=${wallet}`)
        bal = await axios.get(`${process.env.VUE_APP_MAIN_URL}/eth/ethbal?wallet=${wallet}`)
                      .then(res => {return res.data.data})
                      .catch(() => { return '-' })
      } else {
        params = {
          address: tokenAddress,
          decimals: decimals,
          wallet: wallet
        }
        //bal = await axios.get(`https://dex.rugpulled.club/eth/tokenbal`,{params})
        bal = await axios.get(`${process.env.VUE_APP_MAIN_URL}/eth/tokenbal`,{params})
                      .then(res => {return res.data.data})
                      .catch(()=>{return '-'})
      }
      return bal
    },
    closeAndSearch(keyword){
      this.card = false
      EventHub.$emit("TxTable",keyword)
    },
    toLocale(number) {
      if(number==null)return '-'
      return number.toLocaleString("en-US", {minimumFractionDigits: 2})
    }
  },
  watch: {
    btmsheet: function () {
      if(this.card==false){
        this.title = ""
        this.address = ""
        this.tokens = []
      }
    }
  }
}
</script>

<style scoped>
img.icon {
  width: 14px; height: 14px;
}
a, a:link, a:visited {
  text-decoration: none;
  color: #3498db;
}
a:hover {
  text-decoration: underline;
  color: #3498db;
}
</style>